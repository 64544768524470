@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~ngx-toastr/toastr';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Acme&family=Baloo+Da+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@include nb-install() {
  @include nb-theme-global();
};

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ktuassist-primary: mat-palette($mat-indigo);
$ktuassist-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ktuassist-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ktuassist-theme: mat-light-theme((
  color: (
    primary: $ktuassist-primary,
    accent: $ktuassist-accent,
    warn: $ktuassist-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ktuassist-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', sans-serif !important; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


.nb-theme-default nb-layout .layout{
  background-color: white !important;
font-family: 'Baloo Da 2', cursive;
  
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cde4f3; 
}

.mat-icon {
  vertical-align: middle;
}